<template>
  <div class="share-and-like">
    <div class="share-and-like__like-block">
      <p class="share-and-like__title">
        {{ $t('Liked the article?') }}
      </p>
      <div :class="{'share-and-like--hide': isCheckedVote}">
        <div
          class="share-and-like__like-block__like"
          data-transaction-name="Share - Up Vote"
          @click="upVote(postId, mutableLike)"
        >
          <SfImage
            src="/assets/shareAndLike/like.svg"
            class="share-and-like__like-block--image"
          />
          <span class="share-and-like__like-block--count">
            {{ mutableLike }}
          </span>
        </div>
        <div
          class="share-and-like__like-block__dislike"
          data-transaction-name="Share - Down Vote"
          @click="downVote(postId, mutableDislike)"
        >
          <SfImage
            src="/assets/shareAndLike/dislike.svg"
            class="share-and-like__like-block--image"
          />
          <span class="share-and-like__like-block--count">
            {{ mutableDislike }}
          </span>
        </div>
      </div>
    </div>
    <!-- TODO: temporarily hide -->
    <!-- <div class="share-and-like__share-block">
      <p class="share-and-like__title">
        {{ $t('Share the article') }}:
      </p>
      <div class="share-and-like__share-block__social-icons">
        <SfImage src="/assets/shareAndLike/twitter.svg" />
        <SfImage src="/assets/shareAndLike/fb.svg" />
        <SfImage src="/assets/shareAndLike/share.svg" />
      </div>
    </div> -->
  </div>
</template>
<script>
import { SfImage } from '@storefront-ui/vue'
import { mapActions } from 'vuex'

export default {
  name: 'AShareAndLike',
  components: {
    SfImage
  },
  props: {
    postId: {
      type: Number
    },
    like: {
      type: [String, Number],
      default: 0
    },
    dislike: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      isCheckedVote: false,
      mutableLike: this.like || 0,
      mutableDislike: this.dislike || 0
    }
  },
  mounted () {
    this.checkVote()
  },
  updated () {
    this.checkVote()
  },
  methods: {
    ...mapActions({
      updateVote: 'blog/updateVote',
      isVote: 'blog/checkVote'
    }),
    async checkVote () {
      this.isCheckedVote = await this.isVote({ postId: this.postId })
    },
    async upVote (postId, vote) {
      const voteStatus = await this.updateVote({
        postId,
        voteType: 'upvote',
        vote
      })

      this.mutableLike = voteStatus
    },
    async downVote (postId, vote) {
      const voteStatus = await this.updateVote({
        postId,
        voteType: 'downvote',
        vote
      })

      this.mutableDislike = voteStatus
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.share-and-like {
  display: flex;
  align-items: center;
  width: 100%;
  max-height: px2rem(115);
  background: var(--light-gray);
  padding: var(--spacer-22) var(--spacer-20);
  box-sizing: border-box;
  margin: px2rem(30) 0 px2rem(20) 0;

  @media (min-width: $tablet-min) {
    max-height: px2rem(60);
  }

  &--hide {
    opacity: 0.5;
    pointer-events: none;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    min-width: 50%;

    @media (min-width: $tablet-min) {
      flex-direction: row;
    }
  }

  &__title {
    font-size: var(--font-size-13);
    white-space: nowrap;

    @include for-tablet {
      width: min-content;
    }
  }

  &__share-block {
    justify-content: end;

    > p {
      margin: 0 0 var(--spacer-12) 0;

      @media (min-width: $tablet-min) {
        margin: 0 var(--spacer-20) 0 0;
      }
    }

    &__social-icons {
      display: flex;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: px2rem(40);
        height: px2rem(40);
        background: var(--white);
        border-radius: 50%;
        margin-right: var(--spacer-10);
        cursor: pointer;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__like-block {
    &__dislike,
    &__like {
      display: flex;
      align-items: center;
      background: var(--white);
      width: px2rem(62);
      border-radius: 5px;
      margin-right: var(--spacer-12);
      padding: var(--spacer-7) var(--spacer-12) var(--spacer-7) var(--spacer-7);
      cursor: pointer;
      box-sizing: border-box;

      @media (min-width: $tablet-min) {
        width: px2rem(70);
        margin-right: var(--spacer-14);
        padding: var(--spacer-9) var(--spacer-15) var(--spacer-9) var(--spacer-9);
      }
    }

    &__dislike {
      margin-right: 0;
    }

    &--image {
      min-width: px2rem(20);
      max-height: px2rem(20);
      margin-right: var(--spacer-7);
      overflow: unset;

      @media (min-width: $tablet-min) {
        min-width: px2rem(23);
        max-height: px2rem(23);
        margin-right: var(--spacer-7);
      }
    }

    &--count {
      font-size: var(--font-size-12);

      @media (min-width: $tablet-min) {
        font-size: var(--font-size-13);
      }
    }

    > p {
      margin: 0 0 var(--spacer-17) 0;

      @media (min-width: $tablet-min) {
        margin: 0 var(--spacer-20) 0 0;
      }
    }

    > div {
      display: flex;
    }
  }
}
</style>

<template>
  <MCarouselItems
    :items="posts"
    :slider-settings="sliderSettings"
    carousel-class="articles-carousel"
  >
    <template slot-scope="{ item }">
      <MBlogPost
        :post-id="item.post_id"
        :img="item.featured_img"
        :views="item.views_count"
        :title="item.title"
        :date="item.publish_time"
        :like="item.upvote"
        :dislike="item.downvote"
        :identifier="item.identifier"
      />
    </template>
  </MCarouselItems>
</template>
<script>
import {
  BREAKPOINT_XSM,
  BREAKPOINT_XS,
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG
} from 'theme/helpers/breakpoints'
import MCarouselItems from 'theme/components/molecules/m-carousel-items'
import MBlogPost from 'theme/components/molecules/m-blog-post.vue'

export default {
  name: 'MBlogReadAlso',
  props: {
    posts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        perView: 4,
        gap: 10,
        dragThreshold: false,
        peek: {
          before: 0,
          after: 0
        },
        breakpoints: {
          [BREAKPOINT_LG]: {
            perView: 4
          },
          [BREAKPOINT_MD]: {
            perView: 4
          },
          [BREAKPOINT_SM]: {
            perView: 3
          },
          [BREAKPOINT_XSM]: {
            perView: 2
          },
          [BREAKPOINT_XS]: {
            perView: 1,
            peek: {
              before: 0,
              after: 150
            }
          }
        }
      }
    }
  },
  components: {
    MCarouselItems,
    MBlogPost
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/breakpoints';
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.articles-carousel {
  position: relative;

  ::v-deep {
    .post__description {
      max-height: 5rem;
    }

    .sf-carousel__controls {
      display: none;
      transform: var(--carousel-controls-transform, translate3d(0, -50%, 0));
      justify-content: var(--carousel-controls-justify-content, space-between);

      @media (min-width: $tablet-min) {
        display: flex;
      }
    }

    .glide__slide {
      cursor: pointer;
      max-width: px2rem(238);

      @include for-desktop {
        max-width: px2rem(290);
      }
    }
  }
}
</style>

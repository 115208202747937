<template>
  <div v-html="content" />
</template>
<script>
export default {
  name: 'MPostStaticContent',
  props: {
    content: {
      type: String
    }
  }
}
</script>

<template>
  <div
    class="post-page"
    v-if="!isPostPage && !isLoadingPage"
  >
    <m-breadcrumbs />
    <json-ld-breadcrumb />
    <div class="post-page__content">
      <SfImage
        class="post-page__banner"
        :src="imageUrl(postPage)"
      />
      <div class="post-page__static-content">
        <ABlogTimeAndViews
          :post-id="postPage.id"
          :date="postPage.publish_time"
          :year="postPage.year"
          :views="postPage.views_count"
        />
        <h1 class="post-page__title">
          {{ postPage.title }}
        </h1>
        <MPostStaticContent :content="postPage.content" />
        <AShareAndLike
          :post-id="postPage.id"
          :like="postPage.upvote"
          :dislike="postPage.downvote"
        />
        <!-- TODO: hide temporarily -->
        <!-- <AArticleTags
          :tags="postPage.tags"
        /> -->
      </div>
    </div>
    <div
      v-if="postPage.related"
      class="post-page__read-also"
    >
      <p>{{ $t('Read also') }}</p>
      <MBlogReadAlso
        :posts="postPage.related"
        arrow-color="white"
        :show-qty-permanently="true"
      />
    </div>
  </div>
  <ALoadingSpinner
    v-else
    v-show="isLoadingPage"
    :is-absolute-position="true"
  />
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { isServer, getThumbnailPath } from '@vue-storefront/core/helpers';
import MBreadcrumbs from 'theme/components/molecules/m-breadcrumbs'
import ABlogTimeAndViews from 'theme/components/atoms/a-blog-time-and-views'
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import MPostStaticContent from 'theme/components/molecules/m-post-static-content'
import { SfImage } from '@storefront-ui/vue'
import AShareAndLike from 'theme/components/atoms/a-share-and-like'
// import AArticleTags from 'theme/components/atoms/a-article-tags'
import DeviceType from 'theme/mixins/DeviceType'
import MBlogReadAlso from 'theme/components/molecules/m-blog-read-also.vue'

export default {
  name: 'BlogPage',
  components: {
    MPostStaticContent,
    ALoadingSpinner,
    MBreadcrumbs,
    JsonLdBreadcrumb,
    ABlogTimeAndViews,
    SfImage,
    // AArticleTags,
    AShareAndLike,
    MBlogReadAlso
  },
  mixins: [DeviceType],
  computed: {
    ...mapGetters({
      postPage: 'blog/getPostPage',
      isLoadingPage: 'blog/getIsLoadingBlog',
      posts: 'blog/getPosts'
    }),
    isPostPage () {
      return this.postPage.constructor === Array && this.postPage.length === 0
    },
    ...mapState({
      categoryLoader: state => state.ui.categoryLoader
    }),
    isLoading () {
      return this.categoryLoader
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.setBreadcrumbs()
      }
    }
  },
  async asyncData ({ store, route }) {
    await Promise.all([
      store.dispatch('blog/loadPostByUrl', { identifier: route.params.slug }),
      store.dispatch('ui/loadCatalogMenu')
    ])

    if (!isServer) {
      store.dispatch('ui/categoryLoader', false)
    }
  },
  created () {
    if (this.isPostPage) {
      this.$router.push(this.localizedRoute('/blog'))
    }
  },
  methods: {
    imageUrl (url) {
      if (this.$mq === 'xsm' || this.$mq === 'xs') {
        return getThumbnailPath(`/${url.main_img_mobile}`)
      } else if (this.isTablet) {
        return getThumbnailPath(`/${url.main_img_tablet}`)
      } else {
        return getThumbnailPath(`/${url.main_img}`)
      }
    },
    setBreadcrumbs () {
      const routes = [
        {
          name: this.$t(`Blog`),
          route_link: this.localizedRoute('/blog')
        }
      ]

      this.$store.dispatch('breadcrumbs/set', {
        current: this.postPage.title,
        routes: routes
      }, { root: true })
    }
  },
  metaInfo () {
    const isKey = (k, obj) => (k in obj)

    const title = isKey('meta_title', this.postPage)
    const metaDesc = isKey('meta_description', this.postPage) ? this.postPage.meta_description : ''
    const metaKeywords = isKey('meta_keywords', this.postPage) ? this.postPage.meta_keywords : ''
    const metaTitle = title ? this.postPage.meta_title : ''

    return mergeMeta({
      title: metaTitle,
      meta: {
        title: {
          name: 'title',
          content: metaTitle
        },
        description: {
          name: 'description',
          content: metaDesc
        },
        keywords: {
          name: 'keywords',
          content: metaKeywords
        }
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.post-page {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 var(--spacer-10);
  margin-bottom: px2rem(80);

  &__title {
    margin: 0 0 var(--spacer-12) 0;
    @include header-title;

    @include for-desktop {
      margin-bottom: var(--spacer-30);
    }

    @include for-tablet {
      margin-bottom: var(--spacer-15);
    }
  }

  ::v-deep {
    .blog-time-views {
      margin-bottom: var(--spacer-20);

      @include for-desktop {
        margin-bottom: var(--spacer-25);
      }
    }
  }

  @media (min-width: $tablet-min) {
    padding: 0 var(--spacer-17);
  }

  ::v-deep .sf-breadcrumbs {
    &__list-item {
      @include for-mobile {
        padding-left: 0;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  &__banner {
    width: 100%;
    margin-bottom: 10px;

    @media (min-width: $tablet-min) {
      margin-bottom: 20px;
    }

    @include for-desktop {
      max-width: px2rem(989);
    }

    @include for-tablet {
      max-width: px2rem(734);
    }

    ::v-deep img {
      width: 100%;
      max-height: px2rem(202);

      @include for-desktop {
        max-height: px2rem(297);
      }
    }
  }

  &__static-content {
    width: 100%;

    @include for-desktop {
      max-width: px2rem(790);
    }

    @include for-tablet {
      max-width: px2rem(613);
    }
  }

  &__read-also {
    margin-top: px2rem(55);

    @include for-desktop {
      margin-top: px2rem(100);
    }

    ::v-deep .blog-time-views {
      margin-bottom: 0;
    }

    p {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
      margin: 0 0 var(--spacer-20) 0;

      @include for-desktop {
        margin: 0 0 var(--spacer-20) 0;
      }

      @media (min-width: $tablet-min) {
        font-size: var(--font-size-32);
      }
    }
  }
}
</style>
